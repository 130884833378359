<template>
  <div class="schoolSet">
    <div class="conent">
      <!-- 栏目页大图 -->
      <div class="lbt">
        <img src="../assets/img/banner.jpg" />
      </div>
      <div class="w1380 lmy">
        <div class="page-herader">
          <span>学院设置</span>
        </div>
        <div class="lmy-center">
          <div class="lanmu_content">
            <ul>
              <!-- <li v-for="(item,index) in testArr" :key="index" @click="jump(item.name)">{{ item.title }}</li> -->
              <li v-for="(item, index) in listArray" :key="index">
                <a :href="item.mapProperties.erOriginLink" target="_blank">{{
                  item.title
                }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listArray: [],
      testArr: [
        {
          title: "旅游管理",
          name: "tourismManagement",
        },
        {
          title: "国家文化发展国际战略研究院",
          name: "nationalCulturalDevelopment",
        },
      ],
    };
  },
  mounted() {
    this.getDetailsFun();
  },
  methods: {
    async getDetailsFun() {
      const params = {
        pageNumber: 0,
        pageSize: 100,
        columnIds: this.$columnIdFile.xxxysz,
      };
      const res = await this.API.basic.getHomeRotographList(params);
      this.listArray = res.data.content;
    },
    jump(name) {
      const path = this.$router.resolve({
        name,
        query: {
          routeType: 2,
        },
      });
      window.open(path.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.schoolSet {
  background: #f5f5f5;
  padding-bottom: 50px;
  .conent .lbt {
    position: relative;
  }
  .conent .lbt img {
    width: 100%;
  }
  .w1380 {
    width: 1380px;
    margin: 0 auto;
  }
  .lmy {
    background: #fff;
  }
  .lanmu_content {
    padding: 0 0 40px 0;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  ul li {
    width: 420px;
    margin-left: 20px;
    margin-right: 20px;
    border-bottom: solid 1px #e3e3e3;
    padding: 20px;
    font-size: 20px;
    color: #333;
    cursor: pointer;
    a {
      color: #333;
      font-size: 20px;
      line-height: 20px;
    }
  }
}
</style>
